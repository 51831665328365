<template>
	<div>
		<!-- 头部logo -->
		<headLogo @navigation="navigation"></headLogo>
		
		<!-- 头部导航 -->
		<navigation :headTitle="headTitle" :headImg="headImg" style="margin-top:96px;"></navigation>
		
		<el-row class="presidentmessage">
			<el-row class="president-imgdata">
				<!-- <el-row v-if="isimg==true">
					<img :src="leaderNotLoadImage" :onload="onLoadImg(img)" class="president-img"></img>
				</el-row> -->
				<el-row>
					<el-row v-for="(item,itemIdx) in list" class="leadership" :key="itemIdx">
						<el-row v-if="item.pictureUrl">
							<img :src="item.pictureUrl" class="president-img"/>
						</el-row>
						<el-row class="leadership-englishname">
							{{item.leadName}}
						</el-row>
						<div v-for="(ele,idx) in item.leadPost" :key="idx">
							<el-row class="leadership-post"  v-if="ele">
								{{ele}}
							</el-row>
						</div>
						
						<el-row>
							<el-image class="news-chainimg" src="/home/chain.png"></el-image>
						</el-row>
					</el-row>
				</el-row>
			</el-row>
		</el-row>
		
		<!-- 菜单导航 -->
		<meauDialog :meauValue="navigationvalue" @meauFn="menu"></meauDialog>
		
		<!-- 底部导航 -->
		<bottomNavigation></bottomNavigation>
	</div>
</template>

<script>
	import { selectContentListById , selectLeadershipById } from '@/api/index.js'
	
	// 头部组件
	import headLogo from '@/components/headLogo.vue'
	// 头部导航
	import navigation from '@/components/navigation.vue'
	// 底部导航
	import bottomNavigation from '@/components/bottomNavigation.vue'
	// 菜单导航
	import meauDialog from '@/components/meauDialog.vue'
	export default
	{
		data()
		{
			return{
				// 头部导航标题
				headTitle:'EXECUTIVES',
				// 头部导航图片
				headImg:'/index/message.png',
				// 导航值
				navigationvalue:false,
				
				list:[],
				img:'/PCimage/375_500.jpg',
				isimg:true,
				leaderNotLoadImage: "/PCimage/375_500.jpg", //领导图片未加载出来
			}
		},
		components:
		{
			headLogo,
			navigation,
			bottomNavigation,
			meauDialog
		},
		created()
		{
			this.imgList()
		},
		methods:
		{
			// 头部传来的数据
			navigation(v)
			{
				this.navigationvalue=v;
				console.log(this.navigationvalue,'头部传递菜单导航')
			},
			// 遮罩层传回来的数据
			menu(v)
			{
				this.navigationvalue=v;
			},
			
			// 领导层图片
			imgList()
			{
				selectLeadershipById(
				{
				  chnanelType: 1,
				  isIndex: 0,
				  moduleType: 'MODULE_LEADERSHIP'
				})
				.then((response)=>
				{
					if(response.data.data=='')
					{
						this.$router.push(
						{
							path:'/error',
							item:2,
						})
					}
					else
					{
						response.data.data.forEach(element => {
							if(element.leadPost) element.leadPost = element.leadPost.split(",")
						});
						this.list=response.data.data;
						// 以下逻辑为只展示第一位领导的照片；现在需求改为只要有图片就显示出来，所以先注释掉
						// if(this.list[0].pictureUrl==null || this.list[0].pictureUrl=='')
						// {
						// 	this.isimg=false;
						// }
						// else
						// {
						// 	this.isimg=true;
						// 	this.img=this.list[0].pictureUrl;
						// }
					}
				})
			},
			// 图片加载完成时触发
			onLoadImg(imgSrc) 
			{
				return 'this.src='+'"'+imgSrc+'";this.οnlοad=null';
			},
		}
	}
</script>

<style scoped>
	.presidentmessage{
		width: 60%;
		margin-left: 20%;
	}
	.president-imgdata{
		width: 100%;
		position: relative;
	}
	.president-img{
		width: 375px;
		height: 500px;
	}
	.president-data{
		height: 50px;
		background-color: rgba(12,12,12,0.5);
		position: absolute;
		bottom: 3px;
		line-height: 50px;
		color: white;
		font-size: 20px;
		text-align: center;
		padding-left: 3px;
		padding-right: 3px;
	}
	.leadership{
		width: 100%;
		border-bottom: solid 2px #ccc;
		margin-bottom: 40px;
	}
	.leadership-englishname{
		font-size: 30px;
		color: #505050;
		margin-top: 43px;
	}
	.leadership-post{
		font-size: 20px;
		color: #505050;
		margin-top: 20px;
	}
	.news-chainimg{
		width: 128px;
		height: 13px;
		margin-top: 20px;
		margin-bottom: 20px;
	}
</style>